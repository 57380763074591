<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
        <v-spacer></v-spacer>
        <v-toolbar-title>Informe Servicios</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="2" class="mb-n8">
            <label for="">Fecha inicial</label>
            <v-menu
              v-model="menu_fecha_inicial"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_inicial"
                  @click:append="menu_fecha_inicial = true"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha_inicial"
                @input="menu_fecha_inicial = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <label for="">Fecha final</label>
            <v-menu
              v-model="menu_fecha_final"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_final"
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="menu_fecha_final = true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Campo requerido']"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha_final"
                :min="fecha_inicial"
                @input="menu_fecha_final = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="1"
            class="d-flex align-center justify-center mt-n8 mt-sm-0 mt-md-n5 mt-lg-0 mb-3 mb-sm-0 mb-lg-0"
          >
            <v-btn color="primary" @click="consultarServicios">
              Buscar
              <v-icon class="ml-1"> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-col v-if="!consultado">
          <v-row class="justify-center">
            <v-col
              cols="12"
              sm="8"
              md="4"
              lg="4"
              v-for="(card, index) in cards"
              :key="index"
            >
              <v-card>
                <v-toolbar color="primary" dark height="40">
                  <v-spacer></v-spacer>
                  <v-toolbar-title>{{ card.titulo }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    cols="12"
                    v-for="(content, index2) in card.contenido"
                    :key="index2"
                  >
                    <v-col cols="12" class="d-flex justify-center mt-n6">
                      <h3
                        style="font-weight: bold; display: block"
                        class="mr-1"
                      >
                        {{ content.titulo }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center pa-0 ma-0 mt-n1"
                    >
                      <span v-if="index != 0">$</span
                      >{{ content.valor | moneda }}
                    </v-col>
                  </v-col>
                </v-card-text>
                <v-col cols="12" class="d-flex justify-center pa-0 ma-0 mt-n6">
                  <h3 style="font-weight: bold; display: block" class="mr-1">
                    {{ card.total.titulo }}
                  </h3>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pa-0 ma-0">
                  <span v-if="index != 0">$</span
                  >{{ card.total.valor | moneda }}
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
          "
        >
          <v-spacer></v-spacer>
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-spacer></v-spacer>
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import sensorialService from "./services/sensorialService";
import loginService from "@/services/loginService.js";
export default {
  data: () => ({
    fecha_inicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu_fecha_inicial: false,

    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu_fecha_final: false,

    cards: [
      {
        titulo: "Servicios",
        contenido: [
          { titulo: "MOTO:", valor: 0 },
          { titulo: "CARRO:", valor: 0 },
        ],
        total: { titulo: "TOTAL:", valor: 0 },
      },
      {
        titulo: "Utilidad Bruta",
        contenido: [
          { titulo: "MOTO:", valor: 0 },
          { titulo: "CARRO:", valor: 0 },
        ],
        total: { titulo: "TOTAL UTIL.BRUTA:", valor: 0 },
      },
      {
        titulo: "Recaudo Total",
        contenido: [
          { titulo: "MOTO:", valor: 0 },
          { titulo: "CARRO:", valor: 0 },
          { titulo: "IVA:", valor: 0 },
        ],
        total: { titulo: "REC.TOTAL:", valor: 0 },
      },
    ],
    consultado: false,
  }),
  created() {},
  methods: {
    async consultarServicios() {
      this.consultado = true;

      this.cards.forEach((card) => {
        card.total.valor = 0;
        card.contenido.forEach((content) => {
          content.valor = 0;
        });
      });

      await sensorialService
        .informeSensorial({
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final,
        })
        .then((response) => {
          let servicios = response.data;
          let cards_nuevas = [];

          if (servicios.length > 0) {
            servicios.forEach((servicio) => {
              if (servicio.id_tipo_vehiculo == 1) {
                this.cards[0].contenido[1].valor++;
                this.cards[0].total.valor++;

                this.cards[1].contenido[1].valor += parseInt(
                  servicio.valor_servicio
                );
                this.cards[1].total.valor += parseInt(servicio.valor_servicio);

                this.cards[2].contenido[1].valor += parseInt(servicio.tarifa);
                this.cards[2].total.valor += parseInt(servicio.tarifa);
              } else {
                this.cards[0].contenido[0].valor++;
                this.cards[0].total.valor++;

                this.cards[1].contenido[0].valor += parseInt(
                  servicio.valor_servicio
                );
                this.cards[1].total.valor += parseInt(servicio.valor_servicio);

                this.cards[2].contenido[0].valor += parseInt(servicio.tarifa);
                this.cards[2].total.valor += parseInt(servicio.tarifa);
              }
              this.cards[2].contenido[2].valor += parseInt(servicio.iva);
            });
          }
        });

      this.consultado = false;
    },
  },
};
</script>
<style></style>
